/* Contact.css */
#contact {
    margin-top: 30px;
    padding: 50px 20px;
    text-align: center;
    position: relative;
    overflow: hidden;
  }
  
  .contact-content {
    max-width: 600px;
    margin: 0 auto;
    animation: fadeIn 1.5s ease;
  }
  
  .contact-content p {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 20px;
  }
  
  .contact-form {
    margin-bottom: 30px;
    animation: fadeIn 1.5s ease;
  }
  
  .form-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .form-group label {
    display: block;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    border-color: #ffcc00;
    box-shadow: 0 0 5px rgba(0, 119, 204, 0.5);
    outline: none;
  }
  
  .submit-btn {
    background-color: #ffcc00;
    color: black;
    font-size: 1rem;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-family: 'Oswald';
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .submit-btn:hover {
    background-color: #ffcc00;
    transform: translateY(-3px);
  }
  
  .contact-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .contact-content li {
    margin: 15px 0;
    font-size: 1.1rem;
  }
  
  .contact-content a {
    text-decoration: none;
    color: #ffcc00;
    font-weight: bold;
    transition: color 0.3s ease, transform 0.3s ease;
    display: inline-flex;
    align-items: center;
    gap: 10px;
  }
  
  .contact-content a:hover {
    color: #ffcc00;
    transform: translateY(-3px);
  }
  
  .contact-content i {
    font-size: 1.5rem;
    color: #ffcc00;
    transition: color 0.3s ease;
  }
  
  .contact-content a:hover i {
    color: #ffcc00;
  }
  
  .contact-content li:last-child {
    color: #999;
    font-style: italic;
    font-size: 1rem;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  