#testimonials {
    margin-top: 30px;
    padding: 50px 20px;
    text-align: center;
    position: relative;
    overflow: hidden;
}

#testimonials h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

/* Carousel styles */
.carousel {
    position: relative;
    max-width: 1000px;
    width: 100%; /* Ensures it scales responsively */
    height: 300px; /* Fixed height for larger screens */
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.carousel-item {
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 0.5s ease, transform 0.5s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.carousel-item.active {
    opacity: 1;
    transform: translateX(0);
    position: relative;
}

/* Message and author styles */
.message {
    font-size: 1.8rem; /* Slightly smaller for flexibility */
    color: #ffffff;
    line-height: 1.6;
    margin-bottom: 15px;
    font-style: italic;
    text-align: center;
    padding: 0 20px;
}

.author {
    font-size: 1rem;
    color: #ffcc00;
    font-weight: bold;
    text-align: center;
}

/* Carousel controls */
.carousel-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1.5rem;
    z-index: 100;
}

.carousel-control.prev {
    left: 10px;
}

.carousel-control.next {
    right: 10px;
}

.carousel-control:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
    #testimonials h2 {
        font-size: 2rem; /* Reduce heading size */
    }

    .message {
        font-size: 1.2rem; /* Smaller text for better readability */
        padding: 0 10px; /* Adjust padding for smaller screens */
    }

    .author {
        font-size: 0.9rem; /* Slightly smaller author text */
    }

    .carousel-control {
        width: 35px;
        height: 35px;
        font-size: 1.2rem; /* Smaller buttons for mobile */
    }

    .carousel-control.prev {
        left: 5px; /* Adjust control positioning */
    }

    .carousel-control.next {
        right: 5px;
    }
}
