/* Offerings.css */

#offerings {
    padding: 80px 20px; /* Extra padding at the top to account for the navbar */
    background-color: transparent;
    text-align: center;
    overflow: hidden;
  }
  
  h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    animation: slideDown 1s ease-out;
  }
  
  .offerings-content {
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 30px;
    animation: fadeIn 1.5s ease-in;
  }
  
  .offerings-content h3 {
    font-size: 1.8rem;
    color: #ffdd57; /* Golden-yellow for headings */
    margin-bottom: 15px;
    animation: slideRight 1s ease-out;
  }
  
  .offerings-content p {
    font-size: 1.1rem;
    color: #ddd; /* Light gray for text */
    line-height: 1.6;
    margin-bottom: 20px;
    animation: fadeIn 2s ease-in-out;
  }
  
  .offerings-content ul {
    list-style: none;
    padding: 0;
  }
  
  .offerings-content ul li {
    font-size: 1rem;
    color: #fff; /* White for list items */
    margin: 10px 0;
    animation: fadeUp 0.5s ease-in forwards;
    opacity: 0;
  }
  
  .offerings-content ul li:nth-child(1) { animation-delay: 0.5s; }
  .offerings-content ul li:nth-child(2) { animation-delay: 0.7s; }
  .offerings-content ul li:nth-child(3) { animation-delay: 0.9s; }
  .offerings-content ul li:nth-child(4) { animation-delay: 1.1s; }
  .offerings-content ul li:nth-child(5) { animation-delay: 1.3s; }
  .offerings-content ul li:nth-child(6) { animation-delay: 1.5s; }
  
  /* Animations */
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideDown {
    from {
      transform: translateY(-50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes slideRight {
    from {
      transform: translateX(-100px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes fadeUp {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  