nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    padding: 10px 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

.logo {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.logo img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid white;
    animation: borderGlow 2s infinite;
}

.logo p {
    color: #ffbf1c;
    margin-left: 10px;
    font-family: 'Oswald';
    font-size: 1.2rem;
}

.logo p span {
    display: block;
    font-size: 0.8rem;
    color: #ffbf1c;
}

.nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
    transition: all 0.3s ease-in-out;
}

.nav-links li {
    color: #fff;
    font-family: 'Oswald', sans-serif;
    font-size: 1rem;
    cursor: pointer;
    transition: color 0.3s;
}

.nav-links li:hover {
    color: #ffbf1c;
}

.mobile-menu {
    display: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #fff;
}

/* Responsive Design */
@media (max-width: 768px) {
    .nav-links {
        display: none;
        flex-direction: column;
        background-color: rgba(0, 0, 0, 0.9);
        position: absolute;
        top: 60px;
        right: 0;
        width: 200px;
        border-radius: 5px;
        padding: 15px;
        gap: 10px;
        z-index: 200;
    }

    .nav-links.active {
        display: flex;
    }

    .mobile-menu {
        display: block;
    }
}
