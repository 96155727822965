/* Workshops.css */

#workshops {
    padding: 80px 20px; /* Padding for top navbar adjustment */
    background-color: transparent;
    text-align: center;
    overflow: hidden;
  }
  
  h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #ffcc00; /* Golden-yellow for title */
    animation: glowText 2s infinite;
  }
  
  .workshops-content {
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 30px;
    animation: fadeIn 1.5s ease-in;
  }
  
  .workshops-content h3 {
    font-size: 1.8rem;
    color: #fff; /* White text for headings */
    margin-bottom: 20px;
    animation: slideRight 1.5s ease-out;
  }
  
  .workshops-content ul {
    list-style: none;
    padding: 0;
  }
  
  .workshops-content ul li {
    font-size: 1.2rem;
    color: #ddd; /* Light gray for list items */
    margin: 20px 0;
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    animation: fadeUp 0.5s ease-in forwards;
    opacity: 0;
  }
  
  .workshops-content ul li:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.3);
  }
  
  .workshops-content ul li strong {
    color: #ffcc00; /* Golden color for emphasis */
    font-weight: bold;
  }
  
  /* List item animation delays */
  .workshops-content ul li:nth-child(1) { animation-delay: 0.5s; }
  .workshops-content ul li:nth-child(2) { animation-delay: 0.7s; }
  
  /* Animations */
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideRight {
    from {
      transform: translateX(-100px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes fadeUp {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  