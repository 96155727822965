/* Home.css */

/* Reset and global styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: black;
    font-family: 'Oswald', sans-serif;
    color: white;
    scroll-behavior: smooth;
}

/* Keyframes for animations */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideInLeft {
    0% {
        opacity: 0;
        transform: translateX(-50px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

/* Home Container */
.home {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: black;
    position: relative;
    overflow: hidden;
    animation: fadeIn 1.5s ease-out;
}

/* Left Content Section */
.home-content {
    flex: 1;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    z-index: 1;
    animation: slideInLeft 1.2s ease-out;
}

.home-content h1 {
    font-size: 2.3rem;
    margin-bottom: 20px;
    line-height: 1.2;
    animation: zoomIn 1.2s ease-out;
}

.home-content h1 span {
    font-family: 'Vujahday Script', cursive;
    font-size: 1.5rem;
}

.home-content p {
    font-size: 1.2rem;
    margin-top: 10px;
    animation: fadeIn 2s ease-out;
}

.social-media {
    margin-top: 20px;
    animation: fadeIn 2.2s ease-out;
}

.social-media button {
    font-size: 1rem;
    padding: 10px 20px;
    border: 2px solid #ffbf1c; /* Dark gold border */
    color: #ffbf1c; /* Dark gold text color */
    background-color: transparent; /* Transparent background */
    cursor: pointer;
    transition: all 0.3s ease;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
}

.social-media button:hover {
    background-color: #ffbf1c; /* Dark gold background */
    color: black; /* Transparent text */
    transform: scale(1.1); /* Slightly enlarge on hover */
    animation: zoomIn 0.3s ease-in-out;
}

/* Right Video Section */
.home-video {
    flex: 1;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeIn 1.8s ease-out;
}

.home-video video {
    width: 100%;
    height: 100%;
    animation: zoomIn 2s ease-out;
}

/* Mobile View */
@media only screen and (max-width: 768px) {
    .home {
        flex-direction: column;
    }

    .home-content {
        position: absolute;
        z-index: 2;
        text-align: center;
        width: 100%;
        padding: 20px;
        background: none;
        animation: fadeIn 1.5s ease-out;
    }

    .home-content h1 {
        font-size: 1.5rem;
        animation: zoomIn 1.2s ease-out;
    }

    .home-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .home-video::before {
        display: none;
    }
}
