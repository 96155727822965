/* About.css */
#about {
    padding: 50px 20px;
    text-align: center;
  }
  
  .container-about {
    background-color: #f9f9f9;
    border-radius: 5px;
    max-width: 1000px; /* Sets a maximum width for the content */
    margin: 0 auto; /* Centers the container */
    margin-top: 100px;
    padding: 10px 15px; /* Adds padding for smaller screens */
    box-sizing: border-box; /* Ensures padding doesn't affect total width */
  }
  
  .divider {
    width: 80px;
    margin: 0 auto 20px;
    animation: fadeIn 1.5s ease-in-out;
  }
  
  .container-about h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
    animation: slideDown 1s ease-out;
  }
  
  .about-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  
  .about-image {
    flex: 1;
    max-width: 300px;
    height: 300px;
    background-image: url('assets/aboutme.jpg'); /* Replace with actual image path */
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    animation: zoomIn 1.5s ease-in-out;
  }
  
  .about-text {
    flex: 1;
    max-width: 500px;
    font-size: 1.2rem;
    line-height: 1.8;
    color: #555;
    animation: fadeInUp 1s ease-in-out;
  }
  
  .about-text p {
    margin: 0;
    text-align: left;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideDown {
    from {
      transform: translateY(-20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes zoomIn {
    from {
      transform: scale(0.8);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  @keyframes fadeInUp {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @media (max-width: 768px) {
    .about-content {
      flex-direction: column;
    }
  
    .about-image,
    .about-text {
      max-width: 100%;
    }
  }
  